import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as Logo } from "./static/images/prop.com-dark-logo.svg";
import "./styles/index.scss";
import Twilio from "./static/images/Twilio.svg";
import Fiat from "./static/images/Fiat.svg";
import CountryLayer from "./static/images/countrylayerlogo.png";
import { ReactComponent as LinkIcon } from "./static/images/link-icon.svg";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchDependencyStatuses } from "./api";

const Items = [
  {
    serviceTitle : "Twilio API",
    serviceURL : "twilio.com",
    usage : "SMS, 2FA",
    status : "active",
    statusSince : 1685099813,
    lastChecked : 1685100024,
    docs : "http://google.com",
    image : Twilio
  },
  {
    serviceTitle : "Fiat Republic",
    serviceURL : "fiatrepublic.com",
    usage : "Deposit, Withdraw",
    status : "inactive",
    statusSince : 1685099813,
    lastChecked : 1685100024,
    docs : "http://google.com",
    image : Fiat
  },
  {
    serviceTitle : "Twilio API",
    serviceURL : "twilio.com",
    usage : "SMS, 2FA",
    status : "active",
    statusSince : 100000,
    lastChecked : 10000,
    docs : "http://google.com",
    image : Twilio
  }
]
const serviceImages = {
  "Twilio API": Twilio,
  "Twilio": Twilio,
  "Fiat Republic": Fiat,
  "Sumsub API" : "https://s.yimg.com/os/creatr-uploaded-images/2022-08/ba47d640-23bb-11ed-9de9-8f02fe690c1d",
  "SendGrid": "https://w7.pngwing.com/pngs/882/513/png-transparent-sendgrid-logo-management-chief-executive-business-business-blue-company-text.png",
  "Country API": CountryLayer,
}
function App() {
  const [services, setServices] = useState();
  const [fetching, setFetching] = useState(false);

  useEffect(()=> {
    const fetchStatuses = async () => {
        try {
            setFetching(true);
            const services = await fetchDependencyStatuses();
            setServices(services);
            
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }
    };
  fetchStatuses();
  }, [])
  const getDiffTime = (time_to_compare) => {

    let diff = moment().diff(moment(time_to_compare), "minutes");
    let time_unit = "mins";
    if(diff > 60){
      diff = moment().diff(moment(time_to_compare), "hours");
      time_unit = "hours";

      if(diff > 24){
        diff = moment().diff(moment(time_to_compare), "days");
        time_unit = "days";

        if(diff > 30){
          diff = moment().diff(moment(time_to_compare), "months");
          time_unit = "months";

          if(diff > 12){
            diff = moment().diff(moment(time_to_compare), "years");
            time_unit = "years";
          }
        }
      }
    }

    return `${diff} ${time_unit}`;
  }
  return (
      <div className="site-container">
          <div className="header-section d-flex justify-content-between">
              <Logo className="logo" />

              <a
                  href="https://app.prop.com"
                  className="header-link d-inline-block"
                  target="_blank"
                  rel="noreferrer"
              >
                  Go to Prop.com
              </a>
          </div>

          <div className="title">Dependency Management</div>
          <div className="sub-title">
              Track and view live uptime of third-party libraries and components
              that Prop.com relies on.
          </div>

          <table className="table-status-panel">
              <thead>
                  <tr className="table-row header">
                      <th> Dependency </th>
                      <th> Usage </th>
                      <th> Status </th>
                      <th> Up/Down time </th>
                      <th> Last assessed </th>
                      <th> Docs </th>
                  </tr>
              </thead>
              <tbody className="">
                  {fetching
                      ? null
                      : (services || []).map((item, ind) => {
                            const is_active = item.status === 'active';

                            return (
                                <tr className="table-row detail" key={ind}>
                                    <td className="image">
                                        <div className="image-container flex-shrink-0">
                                            <img
                                                src={
                                                    serviceImages[
                                                        item.serviceTitle
                                                    ]
                                                }
                                                alt={item.serviceTitle}
                                            />
                                        </div>
                                        <div>
                                            <div className="api-title">
                                                {' '}
                                                {item.serviceTitle}{' '}
                                            </div>
                                            <div className="api-url">
                                                {' '}
                                                {item.serviceURL}{' '}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="usage">
                                        {' '}
                                        {item.usage}{' '}
                                    </td>
                                    <td className="status-container">
                                      <div className="d-flex align-items-center">

                                        <div
                                            className={`status-circle me-2 flex-shrink-0 ${item.status} ${item.description && "propchain-tooltip"} top`}
                                            data-title={item.description}
                                            ></div>
                                        {item.status}
                                            </div>
                                    </td>
                                    <td className="time-check">
                                        {' '}
                                        Last&nbsp;
                                        <span style={{ fontWeight: 600 }}>
                                            {getDiffTime(item.statusSince)}
                                        </span>{' '}
                                    </td>
                                    <td className="last-check">
                                        {' '}
                                        Checked&nbsp;
                                        <span style={{ fontWeight: 600 }}>
                                            {getDiffTime(item.lastChecked)} ago
                                        </span>
                                    </td>
                                    <td className="external-link">
                                        <a
                                            href={item.serviceURL}
                                            className="d-flex align-items-center doc-link"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <LinkIcon className="me-1" /> Read
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
              </tbody>
          </table>

          {/* <div className="table-container">
              <div className="table-row header">
                  <div className="d-flex align-items-center"> Dependency </div>
                  <div className="d-flex align-items-center"> Usage </div>
                  <div className="d-flex align-items-center"> Status </div>
                  <div className="d-flex align-items-center">
                      {' '}
                      Up/Down time{' '}
                  </div>
                  <div className="d-flex align-items-center">
                      {' '}
                      Last assessed{' '}
                  </div>
                  <div className="d-flex align-items-center"> Docs </div>
              </div>

              {fetching
                  ? null
                  : (services || []).map((item, ind) => {
                        const is_active = item.status === 'active';

                        return (
                            <div className="table-row detail" key={ind}>
                                <div className="d-flex">
                                    <div className="image-container flex-shrink-0">
                                        <img
                                            src={
                                                serviceImages[item.serviceTitle]
                                            }
                                            alt={item.serviceTitle}
                                        />
                                    </div>
                                    <div>
                                        <div className="api-title">
                                            {' '}
                                            {item.serviceTitle}{' '}
                                        </div>
                                        <div className="api-url">
                                            {' '}
                                            {item.serviceURL}{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    {' '}
                                    {item.usage}{' '}
                                </div>
                                <div className="d-flex align-items-center status-container">
                                    <div
                                        className={`status-circle me-2 flex-shrink-0 ${item.status} propchain-tooltip top`}
                                        data-title={item.description}
                                    ></div>
                                    {item.status}
                                </div>
                                <div className="d-flex align-items-center">
                                    {' '}
                                    Last&nbsp;
                                    <span style={{ fontWeight: 600 }}>
                                        {getDiffTime(item.statusSince)}
                                    </span>{' '}
                                </div>
                                <div className="d-flex align-items-center">
                                    {' '}
                                    Checked&nbsp;
                                    <span style={{ fontWeight: 600 }}>
                                        {getDiffTime(item.lastChecked)} ago
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <a
                                        href={item.docs}
                                        className="d-flex align-items-center doc-link"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkIcon className="me-1" /> Read
                                    </a>
                                </div>
                            </div>
                        );
                    })}
          </div> */}
      </div>
  );
}

export default App;
